


function Erdiagrampanel(){
    return(
        <div className=" w-[70px]  h-[100vh] bg-collection_black z-larger">
          
            </div>
            
    )
}

export default Erdiagrampanel